import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import NavDropdown from "react-bootstrap/NavDropdown";
import LogoutModal from "../../Components/Modals/LogoutModal/LogoutModal";
import logo from "../../Assets/logo-header.svg";
import "./ParentHeader.css";

const ParentHeader = ({ deleted }) => {
  const currencyDetails = useSelector((state) => state.currencyReducer);

  const [userName, setUserName] = useState([]);
  const dispatch = useDispatch();
  const handleClick = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    setUserName(localStorage.getItem("userFirstName"));
  });

  const setMenuTabValue = (val) => {
    dispatch({
      type: "TAB_CHANGE",
      payload: val,
    });
  };

  return (
    <>
      {["sm"].map((expand) => (
        <Navbar key={expand} expand={expand} className="parent-header" style={{ backgroundColor: "#797979" }}>
          <div className="parent-header-container d-flex justify-content-between flex-grow-1 mx-3">
            <Navbar.Brand className="d-flex flex-grow-1">
              <a href="/">
                <img className="feis-logo" src={logo} alt="logo" />{" "}
              </a>
            </Navbar.Brand>

            {localStorage.getItem("accessToken") && !deleted && (
              <>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  className="parent-mobile-nav"
                  placement="end"
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}></Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <div className="nav-parent">
                      <Nav className="navbar-parent">
                        <NavLink className="navbar-dashboard" exact to="/">
                          Dashboard
                        </NavLink>
                        <div
                          className="navbar-dashboard"
                          style={{ display: "flex", alignItems: "center", marginRight: "8px" }}
                        >
                          Region:
                          {currencyDetails?.country ? (
                            <img
                              src={`https://flagsapi.com/${currencyDetails?.country}/flat/64.png`}
                              alt="flag"
                              style={{ marginLeft: "8px", height: "24px" }}
                            />
                          ) : null}
                        </div>
                        <NavDropdown
                          title={userName}
                          className="dropdown"
                          id={`offcanvasNavbarDropdown-expand-${expand}`}
                        >
                          <NavLink className="menu" exact to="/my-account" onClick={() => setMenuTabValue("1")}>
                            My account
                          </NavLink>
                          <NavLink className="menu" exact to="/my-account" onClick={() => setMenuTabValue("3")}>
                            Dancers
                          </NavLink>
                          <NavLink className="menu" exact to="/parent/transaction-history">
                            Transaction history
                          </NavLink>
                          <NavLink className="menu" onClick={handleClick}>
                            <LogoutModal name="Logout" />
                          </NavLink>
                        </NavDropdown>
                      </Nav>
                    </div>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </>
            )}
          </div>
        </Navbar>
      ))}
    </>
  );
};

export default ParentHeader;
