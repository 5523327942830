import React, { useEffect, useState } from "react";
import { Form, Formik, useFormikContext, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { serviceConsumer } from "../../network/ServiceConsumer";
import { useDispatch } from "react-redux";
import Loader from "../../Components/Loader/Loader";
import { addYears } from "date-fns";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Container } from "react-bootstrap";
import { accessTillAbout, accessTillFees } from "../../helper/CreateDispatches";
import { formatDate } from "../../helper/formatDate";
import FormikControl from "../../Components/formik/FormikControl";

export const currencyData = [
  {
    key: "CAD",
    value: "CAD",
  },
  {
    key: "USD",
    value: "USD",
  },
  {
    key: "Not applicable",
    value: "Not applicable",
  },
];

const FeesFies = (props) => {
  let EventID = localStorage.getItem("ID");
  const [EventDetails, setEventDetails] = useState("");
  const [closingDate, setClosingDate] = useState("");
  const [familyFee, setFamilyFee] = useState();
  const [loading, setLoading] = useState(false);
  const page = localStorage.getItem("page");
  const TAB = localStorage.getItem("TAB");
  const formik = useFormikContext();
  const dispatch = useDispatch();
  const previousEvent = localStorage.getItem("previousEvent");
  const eventType = props.type;

  useEffect(() => {
    accessTillFees(dispatch);
  }, []);

  useEffect(() => {
    getEventDetails();
  }, []);

  const getEventDetails = async () => {
    try {
      setLoading(true);

      if (EventID) {
        const url =
          eventType === "feis"
            ? `${process.env.REACT_APP_BASE_URL}/feis/${EventID}`
            : `${process.env.REACT_APP_BASE_URL}/grade-exam/${EventID}`;
        const response = await serviceConsumer("GET", url);
        setFamilyFee(eventType === "feis" ? response?.Feis?.fees[0] : response?.grade_exams?.fees[0]);
        if (response.success === true) {
          setClosingDate(eventType === "feis" ? response.Feis?.closing_date : response.grade_exams?.closing_date);
          setEventDetails(eventType === "feis" ? response.Feis.fees[0] : response.grade_exams.fees[0]);
          formik.setFieldValue("fees.familyfee", EventDetails?.familyfee);
          page > TAB &&
            localStorage.setItem("page", eventType === "feis" ? response.Feis?.page : response.grade_exams?.page);
          setLoading(false);
        }
        setLoading(false);
      }
      if (!EventDetails) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const ifDateIsBefore = EventDetails?.earlyFee?.ifDateIsBefore;
  const afterDate = EventDetails?.lateFee?.afterDate;

  const EventDetailsValues = {
    familyfee: EventDetails?.familyfee,
    familymax: EventDetails?.familymax,
    familyless: EventDetails?.earlyFee?.familyLess,
    ifDateIsBefore: ifDateIsBefore ? formatDate(ifDateIsBefore) : "",
    additionfee: EventDetails?.lateFee?.additionalFee,
    afterdate: afterDate ? formatDate(afterDate) : "",
    lateadditionfee: EventDetails?.lateFeeAfterClosed?.additionalFee,
    lateafterdate: closingDate ? formatDate(closingDate) : "",
    exchangeCurrency: EventDetails?.exchangeCurrency || "Not applicable",
  };

  const checkValidAfterDate = (formik) => {
    if (afterDate && closingDate) {
      formik.setFieldTouched("afterdate", true);
      setTimeout(() => {
        formik.validateForm();
      }, 0);
    }
  };

  // validation
  const eventDetailsValidationSchema = Yup.object().shape({
    familyfee: Yup.number()
      .min(0, "Family fee must be greater than or equal to zero")
      .label("Family Fee:")
      .required("This field is required."),
    familymax: Yup.number()
      .min(0, "Family max must be greater than or equal to zero")
      .label("Family max:")
      .required("This field is required."),
    familyless: Yup.number()
      .min(0, "Family less must be greater than or equal to zero")
      .label("Family Less:")
      .test({
        test: (value, context) => {
          const values = context.parent;
          if (values.familyfee && value > values.familyfee) return false;
          else return true;
        },
        message: "Family Less must be less than Family Fee",
      })
      .required("This field is required."),
    ifDateIsBefore: Yup.string().label("If date is before:").required("This field is required.").nullable(),
    additionfee: Yup.number()
      .min(0, "additional fee must be greater than or equal to zero")
      .label("Additional:")
      .required("This field is required.")
      .nullable(),
    afterdate: Yup.date()
      .test({
        test: (afterdate, context) => {
          const { lateafterdate } = context.parent;
          if (!!afterdate && !!lateafterdate) {
            return moment(afterdate)?.format("MM/DD/YYYY") < moment(lateafterdate)?.format("MM/DD/YYYY");
          }
          return true;
        },
        message: "Late fee date must be less than closing date",
      })
      .required("This field is required."),
    lateadditionfee: Yup.string()
      .min(0, "late additional fee must be greater than or equal to zero")
      .label("Additional: ")
      .required("This field is required.")
      .nullable(),
    lateafterdate: Yup.string().label("After Date: ").required("This field is required.").nullable(),
  });

  const maxDate = addYears(new Date(), 100);

  // submitHandler
  const FamilyFeesHandler = async (val) => {
    let fees = {
      fees: [
        {
          familyfee: val.familyfee,
          familymax: val.familymax,
          earlyFee: {
            familyLess: val.familyless,
            ifDateIsBefore: moment(val.ifDateIsBefore).format("yyyy-MM-DD"),
          },
          lateFee: {
            additionalFee: val.additionfee,
            afterDate: moment(val.afterdate).format("yyyy-MM-DD"),
          },
          lateFeeAfterClosed: {
            additionalFee: val.lateadditionfee,
            afterDate: moment(val.lateafterdate).format("yyyy-MM-DD"),
          },
        },
      ],
      page: localStorage.getItem("page") > 4 ? localStorage.getItem("page") : 4,
      exchangeCurrency: val.exchangeCurrency || "CAD",
    };

    try {
      setLoading(true);
      const url =
        eventType === "feis"
          ? `${process.env.REACT_APP_BASE_URL}/feis/${EventID}`
          : `${process.env.REACT_APP_BASE_URL}/grade-exam/${EventID}`;
      const response = await serviceConsumer("PUT", url, fees);

      if (response.success === true) {
        props.setValue("5");
        localStorage.setItem("TAB", 5);
        if (Number(localStorage.getItem("page")) < 4) {
          localStorage.setItem("page", 4);
        }
        accessTillAbout(dispatch);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleWheel = (e) => {
    e.preventDefault();
    e.currentTarget.blur(); // Remove focus from the input field
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
      e.currentTarget.blur(); // Remove focus from the input field
    }
  };

  return (
    <Container className="px-0">
      {loading && <Loader />}
      {!loading && (
        <Formik
          initialValues={EventDetailsValues}
          validationSchema={eventDetailsValidationSchema}
          onSubmit={FamilyFeesHandler}
          validateOnChange
          validateOnBlur
        >
          {(formik) => {
            return (
              <Form
                className="form form-label-right"
                autoComplete="off"
                onChange={() => {
                  checkValidAfterDate(formik);
                }}
              >
                <div className="">
                  <div className="detailsHeaders">
                    <h4 className="eventPage">Fees</h4>
                    <p className="eventDescription">Pay with your Stripe account</p>
                  </div>
                  <div className="detailsHeaders">
                    <h4 className="eventPage">Fees</h4>
                    <p className="eventDescription">Choose your currency</p>

                    <FormikControl
                      className={
                        formik.errors.exchangeCurrency && formik.touched.exchangeCurrency
                          ? "input-box-select-error "
                          : "input-box-org-select"
                      }
                      control="select"
                      name="exchangeCurrency"
                      showAs="Select"
                      options={currencyData}
                      id="schoolSelect"
                      setFieldValue={formik.setFieldValue}
                      style={{ fontSize: "12px !important" }}
                    />
                  </div>
                  <div>
                    <h3 className="EventDateOrg">Family Fee and Family Max</h3>
                  </div>
                  <div className="d-flex flex-column ">
                    <div className={formik.errors.familyfee && formik.touched.familyfee ? "" : "input-control"}>
                      <label
                        className={
                          formik.errors.familyfee && formik.touched.familyfee
                            ? "label-head-org-error"
                            : "label-head-org"
                        }
                      >
                        Family Fee:
                      </label>
                      <br />
                      <Field
                        className={
                          formik.errors.familyfee && formik.touched.familyfee ? "input-box-org-error" : "input-box-org"
                        }
                        onWheel={handleWheel}
                        onKeyDown={handleKeyDown}
                        type="number"
                        autoComplete="off"
                        name="familyfee"
                        placeholder="Family Fee"
                        step="any"
                        onKeyPress={(e) => {
                          if (e.key === "e") {
                            e.preventDefault();
                          }
                        }}
                        values={familyFee?.familyfee}
                      />
                      <ErrorMessage className="auth-error" name="familyfee" component="div" />
                    </div>
                    <div className={formik.errors.familymax && formik.touched.familymax ? "" : "input-control"}>
                      <label
                        className={
                          formik.errors.familymax && formik.touched.familymax
                            ? "label-head-org-error"
                            : "label-head-org"
                        }
                      >
                        Family max:
                      </label>
                      <br />
                      <Field
                        className={
                          formik.errors.familymax && formik.touched.familymax ? "input-box-org-error" : "input-box-org"
                        }
                        type="number"
                        autoComplete="off"
                        name="familymax"
                        onWheel={handleWheel}
                        onKeyDown={handleKeyDown}
                        placeholder="Family max:"
                        step="any"
                        onKeyPress={(e) => {
                          if (e.key === "e") {
                            e.preventDefault();
                          }
                        }}
                      />
                      <ErrorMessage className="auth-error" name="familymax" component="div" />
                    </div>
                    <div>
                      <h3 className="EventDateOrg">Early and Late Fees</h3>
                    </div>
                    <span className="EventHeader-org">
                      <h4>Early Fee</h4>
                    </span>
                    <div className={formik.errors.familyless && formik.touched.familyless ? "" : "input-control"}>
                      <label
                        className={
                          formik.errors.familyless && formik.touched.familyless
                            ? "label-head-org-error"
                            : "label-head-org"
                        }
                      >
                        Family Less:
                      </label>
                      <br />
                      <Field
                        className={
                          formik.errors.familyless && formik.touched.familyless
                            ? "input-box-org-error"
                            : "input-box-org"
                        }
                        type="number"
                        autoComplete="off"
                        name="familyless"
                        onWheel={handleWheel}
                        onKeyDown={handleKeyDown}
                        placeholder="Family fee"
                        step="any"
                        onKeyPress={(e) => {
                          if (e.key === "e") {
                            e.preventDefault();
                          }
                        }}
                      />
                      <ErrorMessage className="auth-error" name="familyless" component="div" />{" "}
                    </div>
                    <div
                      className={formik.errors.ifDateIsBefore && formik.touched.ifDateIsBefore ? "" : "input-control"}
                    >
                      <label
                        className={
                          formik.errors.ifDateIsBefore && formik.touched.ifDateIsBefore
                            ? "label-head-org-error"
                            : "label-head-org"
                        }
                      >
                        Ends on:
                      </label>
                      <br />
                      <Field name="ifDateIsBefore">
                        {({ field, form }) => (
                          <DatePicker
                            className={
                              formik.errors.ifDateIsBefore && formik.touched.ifDateIsBefore
                                ? "input-box-org-error"
                                : "input-box-org"
                            }
                            selected={field.value}
                            onChange={(date) => {
                              form.setFieldValue("ifDateIsBefore", date);
                            }}
                            onBlur={() => {
                              formik.setFieldTouched("ifDateIsBefore", true);
                            }}
                            minDate={new Date()}
                            maxDate={maxDate}
                            placeholderText="MM/DD/YYYY"
                          />
                        )}
                      </Field>
                      <ErrorMessage className="auth-error" name="ifDateIsBefore" component="div" />{" "}
                    </div>
                    <span className="EventHeader-org" style={{ paddingTop: "16px" }}>
                      <h4>Extra fee</h4>
                    </span>
                    <div className={formik.errors.additionfee && formik.touched.additionfee ? "" : "input-control"}>
                      <label
                        className={
                          formik.errors.additionfee && formik.touched.additionfee
                            ? "label-head-org-error"
                            : "label-head-org"
                        }
                      >
                        Additional:
                      </label>
                      <br />
                      <Field
                        className={
                          formik.errors.additionfee && formik.touched.additionfee
                            ? "input-box-org-error"
                            : "input-box-org"
                        }
                        type="number"
                        autoComplete="off"
                        name="additionfee"
                        onWheel={handleWheel}
                        onKeyDown={handleKeyDown}
                        placeholder="Additional"
                        step="any"
                        onKeyPress={(e) => {
                          if (e.key === "e") {
                            e.preventDefault();
                          }
                        }}
                      />
                      <ErrorMessage className="auth-error" name="additionfee" component="div" />{" "}
                    </div>
                    <div className={formik.errors.afterdate && formik.touched.afterdate ? "" : "input-control"}>
                      <label
                        className={
                          formik?.errors?.afterdate && formik?.touched?.afterdate
                            ? "label-head-org-error"
                            : "label-head-org"
                        }
                      >
                        Starts on:
                      </label>
                      <br />
                      <Field name="afterdate">
                        {({ field, form }) => (
                          <DatePicker
                            className={
                              formik.errors.afterdate && formik.touched.afterdate
                                ? "input-box-org-error"
                                : "input-box-org"
                            }
                            selected={field.value}
                            onChange={(date) => {
                              form.setFieldValue("afterdate", date);
                              formik.setFieldValue("afterdate", date);
                              formik.setFieldTouched("afterdate", true);
                              setTimeout(() => {
                                formik.validateForm();
                              }, 0);
                            }}
                            onBlur={() => {
                              formik.setFieldTouched("afterdate", true);
                            }}
                            minDate={new Date()}
                            maxDate={maxDate}
                            placeholderText="MM/DD/YYYY"
                          />
                        )}
                      </Field>
                      <ErrorMessage className="auth-error" name="afterdate" component="div" />{" "}
                    </div>
                    <span className="EventHeader-org" style={{ paddingTop: "16px" }}>
                      <h4>Late fee After Closed Registration</h4>
                    </span>
                    <div
                      className={formik.errors.lateadditionfee && formik.touched.lateadditionfee ? "" : "input-control"}
                    >
                      <label
                        className={
                          formik.errors.lateadditionfee && formik.touched.lateadditionfee
                            ? "label-head-org-error"
                            : "label-head-org"
                        }
                      >
                        Additional:{" "}
                      </label>
                      <br />
                      <Field
                        className={
                          formik.errors.lateadditionfee && formik.touched.lateadditionfee
                            ? "input-box-org-error"
                            : "input-box-org"
                        }
                        type="number"
                        autoComplete="off"
                        name="lateadditionfee"
                        placeholder="Additional"
                        step="any"
                        onWheel={handleWheel}
                        onKeyDown={handleKeyDown}
                        onKeyPress={(e) => {
                          if (e.key === "e") {
                            e.preventDefault();
                          }
                        }}
                      />
                      <ErrorMessage className="auth-error" name="lateadditionfee" component="div" />
                    </div>
                    <div>
                      <label
                        className={
                          formik.errors.lateafterdate && formik.touched.lateafterdate
                            ? "label-head-org-error"
                            : "label-head-org"
                        }
                      >
                        Starts on:
                      </label>
                      <br />
                      <Field name="lateafterdate">
                        {({ field, form }) => (
                          <>
                            <DatePicker
                              readOnly
                              className={
                                formik.errors.lateafterdate && formik.touched.lateafterdate
                                  ? "input-box-org-error"
                                  : "input-box-org"
                              }
                              // selected={field.value}
                              selected={field?.value && moment(field?.value).add(1, "days").toDate()}
                              onChange={(date) => {
                                form.setFieldValue("lateafterdate", date);
                              }}
                              onBlur={() => {
                                formik.setFieldTouched("lateafterdate", true);
                              }}
                              minDate={new Date()}
                              maxDate={maxDate}
                              placeholderText="MM/DD/YYYY"
                              disabled
                            />
                          </>
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className=" mt-3 mb-5">
                    <button
                      disabled={(!formik.isValid || !formik.dirty || formik.isSubmitting) && !previousEvent}
                      className={previousEvent ? "filled" : formik.isValid && formik.dirty ? "filled" : "disabled"}
                      type="submit"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </Container>
  );
};

export default FeesFies;
