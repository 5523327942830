import moment from "moment";
import { formatDate } from "../../helper/formatDate";

export const getCurrency = () => {
  const region = localStorage.getItem("Region");
  const exchangeCurrency = localStorage.getItem("exchangeCurrency");

  if (region == "Eastern Canada" || region == "Western Canada") {
    return "CAD";
  } else {
    if (exchangeCurrency === "USD") return "USD";
    return "CAD";
  }
};

export const getConvertedCurrency = (currencyDetails, amt) => {
  const currency = getCurrency();
  if (currency === "USD") {
    let convertedAmount = Number(currencyDetails.conversionRate) * amt;
    convertedAmount = Math.round(convertedAmount);
    return convertedAmount !== 0 ? parseFloat(convertedAmount.toFixed(2)) : 0;
  } else return amt;
};

export const setConversionRate = (dispatch, conversionRate) => {
  dispatch({
    type: "SET_CONVERSION_RATE",
    payload: {
      conversionRate: conversionRate,
    },
  });
};

export const updatePrices = (data, currencyDetails) => {
  // Use Object.entries to loop through key-value pairs
  Object.entries(data).forEach(([level, items]) => {
    // Loop through each item in the level's array
    items.forEach((item) => {
      item.price = getConvertedCurrency(currencyDetails, item.price);
    });
  });
  return data;
};

export const updateGradePrices = (data, currencyDetails) => {
  const updatdeData = data.map((item) => {
    return { ...item, grade_fee: getConvertedCurrency(currencyDetails, item.grade_fee) };
  });
  return updatdeData;
};

export const getServiceFee = (amount, currencyDetails, org) => {
  const convertedHundred = org ? 100 : getConvertedCurrency(currencyDetails, 100);
  const convertedTwoHundred = org ? 200 : getConvertedCurrency(currencyDetails, 200);
  const convertedTwenty = org ? 20 : getConvertedCurrency(currencyDetails, 20);
  const convertedTen = org ? 10 : getConvertedCurrency(currencyDetails, 10);

  let serviceAmount = 0;
  if (amount <= convertedHundred) {
    serviceAmount = convertedTen;
  } else if (amount > convertedHundred && amount <= convertedTwoHundred) {
    serviceAmount = Math.round(amount / 10);
  } else serviceAmount = convertedTwenty;

  return serviceAmount;
};

export const getEditServiceFee = (amount) => {
  if (amount <= 50) {
    return 2.5;
  }
  return Number(Number((amount / 20).toFixed(2)));
};

export const getFamilyFess = (eventDetails, currencyDetails) => {
  if (eventDetails) {
    const familyLess = eventDetails?.fees[0]?.earlyFee?.familyLess;
    const lateFee = eventDetails?.fees[0]?.lateFee?.additionalFee;

    let familyfee = eventDetails.fees[0]?.familyfee;
    const now = moment().startOf("day");

    const beforeDate = moment(formatDate(eventDetails?.fees[0]?.earlyFee?.ifDateIsBefore)).startOf("day");
    if (now.isSameOrBefore(beforeDate)) {
      familyfee -= familyLess;
    }
    const afterDate = moment(formatDate(eventDetails?.fees[0]?.lateFee?.afterDate)).startOf("day");

    if (now.isSameOrAfter(afterDate)) {
      familyfee += lateFee;
    }
    return getConvertedCurrency(currencyDetails, familyfee);
  }
  return 0;
};
