import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RegisterInFiesanaaEvent from "../RegisterInFiesanaaEvent";
import Loader from "../../../Components/Loader/Loader";
import { serviceConsumer } from "../../../network/ServiceConsumer";
import ParentHeader from "../../../Parent/ParentHeader/ParentHeader";
import { agesCode } from "../../../helper/DataArrays";
import "./style.css";
import { filterCompBasedOnAgesAndTeams, setSelectedDataFromLevelData, sortLevels } from "../../../helper/RegFlowHelpers";
import RegisterTitle from "../Components/RegisterTitle";
import LevelButton from "../Components/LevelButton";
import CompetitionCheckBox from "../Components/CompetitionCheckBox";
import { getIsTeacher } from "../../../helper/getIsTeacher";
import { useDispatch ,useSelector} from "react-redux";
import { setConversionRate, updatePrices } from "../helper";

const FeiseannazEditEvent = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPaymentPage, setshowPaymentPage] = useState(false);
  const [error, setError] = useState("");
  const [eventDetails, setEventDetails] = useState();
  const [prevTotal, setPrevTotal] = useState(0);
  const [prevFamilyFee, setPrevFamilyFee] = useState(0);
  const [initialLoading, setInitialLoading] = useState(false)
  const [prevDancerTotal, setPrevDancerTotal] = useState(0);

  // all dancer in an event
  const [allDancer, setAllDancer] = useState([]);

  // grouped level wise competitions list for a dancer
  const [levelData, setLevelData] = useState();

  // selected level's competitions list for all dancer, here one dancer in edit flow
  const [selectedLevelData, setSelectedLevelData] = useState([]);

  // selected competitions list for all dancer, here one dancer in edit flow
  const [previousCompetition, setPreviousCompetition] = useState([]);

  // for each dancer selected levels
  const [selectedLevels, setSelectedLevels] = useState([]);

  const searchParams = new URLSearchParams(window.location.search)
  const regId = searchParams.get('regId')
  const params = useParams();
  const eventId = params?.id
  const dispatch = useDispatch()
  const currencyDetails = useSelector(state=>state.currencyReducer)

  const backToViewEvent = () => {
    window.history.back();
  };

  const handleClick = async (level, index) => {
    const newArr = [...selectedLevels];
    newArr[index] = level;
    setSelectedLevels(newArr)
  };

  const errormsg = (err) => {
    setLoading(false);
    setError(err);
  };

  const clientSecret = (secret) => {
    setLoading(false);
  };

  const setshowPaymentPageHandler = (val) => {
    setLoading(true);
    setshowPaymentPage(val);
  };

  const backToRegisterGradeEventFromErrorPage = () => {
    setshowPaymentPage(false);
    setError("");
  };

  //  to get eventDetails on back
  const getEventDetails = async () => {
    setInitialLoading(true);
    try {
      if (eventId) {
        const url = `${process.env.REACT_APP_BASE_URL}/feis/${eventId}`;
        const response = await serviceConsumer('GET', url);
        setConversionRate(dispatch,response?.Feis?.usdExchangeRate)
        setEventDetails(response.Feis);
        getRegistrationDetail(response.Feis.feis_date)
        localStorage.setItem("exchangeCurrency", response.Feis?.exchangeCurrency);
      }
    } catch (error) {
      navigate("/pageNotFound", { replace: true })
      setInitialLoading(false);
    }
  };

  const getRegistrationDetail = async (feisDate) => {
    const url = `${process.env.REACT_APP_BASE_URL}/registration/regdetails/${regId}`;
    try {
      setInitialLoading(true);
      const response = await serviceConsumer('GET', url);
      if (response.result?.length) {
        setSelectedLevels(response.result[0]?.levels ? [response.result[0]?.levels[0]] : []);
        setPrevFamilyFee(response.result[0]?.fee);
        setPrevTotal(response.result[0]?.allLevelAmount)
        setPrevDancerTotal(response.result[0]?.dancerTotal)

        const uniqueComp = [...new Set(response.result[0]?.competitions)];
        setPreviousCompetition(uniqueComp)

        const data = {
          _id: response.result[0]?.dancer_id,
          event_id: eventId,
          competitions: uniqueComp,
        };

        setSelectedLevelData([data]);

        const dancerAge = new Date(feisDate).getFullYear() - Number(response.result[0]?.dancerData?.birthday)
        const dancerAgeToCompare = dancerAge < 5 ? 5 : dancerAge > 22 ? 23 : dancerAge;
        const ageObject = agesCode.find(age => age.key === (dancerAgeToCompare).toString())

        const dancerDataObj = {
          _id: response.result[0]?.dancer_id,
          fname: response.result[0]?.dancerData?.fname,
          lname: response.result[0]?.dancerData?.lname,
          levels: response.result[0]?.dancerData?.levels,
          ages: ageObject,
          competitions: uniqueComp,
          gender: response.result[0]?.dancerData?.gender,
        }

        setAllDancer([dancerDataObj]);
      }
      setInitialLoading(false);
    } catch (error) {
      navigate("pageNotFound", { replace: true })
      setInitialLoading(false);
    }
  };

  useEffect(() => {
    getEventDetails()
    getIsTeacher(setLoading)
    document.title = "Feis.Link - Register Dancers"
  }, []);

  useEffect(() => {
    if (levelData && allDancer.length !== 0) {
      setSelectedDataFromLevelData(levelData, allDancer, selectedLevelData, setSelectedLevelData)
    }
  }, [levelData, allDancer]);

  const getLevelCompetitions = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/feis/levelwise/competitions/${eventId}`;
    const res = await serviceConsumer('GET', url);
    const levelDataResponse = res.result
    const convertedLevelDataResponse = updatePrices(levelDataResponse,currencyDetails)
    const sortedLevels = sortLevels(convertedLevelDataResponse)
    if (res) setLevelData(sortedLevels)
  }

  useEffect(() => {
    if (eventDetails) {
      getLevelCompetitions()
    }
  }, [eventDetails,currencyDetails]);



  const checkBoxSelectHandler = async (competitionId, dancerDetail, index, price, level) => {
    const dancer = selectedLevelData[index]
    if (dancer?.competitions?.find(item => item === competitionId)) {
      deleteItemHandler(competitionId, dancerDetail?._id, index, level)
    } else {
      setLoading(true)
      const updatedComp = dancer?.competitions ? [...dancer.competitions, competitionId] : [competitionId];

      const newLevelItem = levelData[level]?.find(item => item._id === competitionId);

      // const levelKey = level === 'SPECIALS' ? selectedLevels[index] : level

      // grouped competition list based on level
      let updatedLevelPriceData;

      if (dancer?.levelPriceData && dancer?.levelPriceData[level]) {
        updatedLevelPriceData = { ...dancer?.levelPriceData, [level]: [...dancer?.levelPriceData[level], newLevelItem] };
      } else if (dancer?.levelPriceData) {
        updatedLevelPriceData = { ...dancer?.levelPriceData, [level]: [newLevelItem] };
      } else {
        updatedLevelPriceData = { [level]: [newLevelItem] };
      }
 
      const data = {
        _id: dancerDetail?._id,
        event_id: eventId,
        competitions: updatedComp,
        levelPriceData: updatedLevelPriceData
      };

      const newArr = [...selectedLevelData];
      newArr[index] = data;

      setSelectedLevelData(newArr);
      setTimeout(() => {
        setLoading(false)
      }, 300);
    }
  };

  const deleteItemHandler = (competitionId, dancerId, index, level) => {
    // const levelKey = level === 'SPECIALS' ? selectedLevels[index] : level

    setLoading(true)
    const dancer = selectedLevelData[index];

    if (dancer?.competitions?.length === 1) {
      const newArr = [...selectedLevelData];
      newArr.splice(index, 1);

      setSelectedLevelData(newArr);
    } else {
      const competitionsNew = dancer?.competitions?.filter(competition => competition !== competitionId)

      // grouped competition list based on level
      let updatedLevelPriceData;
      if (dancer?.levelPriceData && dancer?.levelPriceData[level]) {
        updatedLevelPriceData = { ...dancer?.levelPriceData, [level]: [...dancer?.levelPriceData[level]?.filter(it => it._id !== competitionId)] };
      } else if (dancer?.levelPriceData) {
        updatedLevelPriceData = { ...dancer?.levelPriceData };
        delete updatedLevelPriceData[level];
      } else {
        updatedLevelPriceData = {};
      }

      const data = {
        competitions: competitionsNew,
        _id: dancerId,
        event_id: eventId,
        levelPriceData: updatedLevelPriceData
      };

      const newArr = [...selectedLevelData];
      newArr[index] = data;

      setSelectedLevelData(newArr);
    }
    setTimeout(() => {
      setLoading(false)
    }, 300);
  };

  return (
    <div>
      <ParentHeader />
      {loading && <Loader />}
      {initialLoading && <Loader />}
      {!initialLoading && <div className="parent-register row">
        <div className="col-md-7">
          {error && (
            <>
              <RegisterTitle eventDetails={eventDetails} onClick={backToRegisterGradeEventFromErrorPage} />
              <hr className="mt-0 " />
              <div className="col-md-8 offset-md-2 offset-1 col-10 my-5">
                <center><div style={{ color: "red" }}>{error}</div></center>
              </div>
            </>
          )}

          {!showPaymentPage && !error && (
            <>
              <RegisterTitle eventDetails={eventDetails} onClick={backToViewEvent} />
              <hr className="mt-0 " />
              <div className="reg-left-container">
                {allDancer?.map((dancer, index) => {
                  let specialsList = filterCompBasedOnAgesAndTeams('SPECIALS', dancer, levelData)
                  specialsList = specialsList?.length > 0 ? specialsList.filter(item => item.levels.includes(selectedLevels[index] || item.levels.includes(selectedLevels[index]?.split(" ")[0]))) : specialsList
                  const levelHasSpecials = selectedLevels[index] && specialsList?.length > 0

                  return (
                    <div className="col-md-8 offset-md-2 offset-1 col-10 my-5" key={dancer._id}>
                      <h5>
                        Register dancer:&nbsp;
                        <span style={{ color: "#68ad3f" }}>{dancer.fname} {dancer.lname}</span>
                      </h5>
                      <hr className="mt-0 mb-4" />
                      <h6 style={{ color: "#323232", fontSize: "12px", }} className="my-2"> Choose Levels:</h6>
                      <div className="buttonGroup">
                        {/* for each level in levelData show a level button */}
                        {levelData && Object.entries(levelData).map(([level, data]) =>
                          filterCompBasedOnAgesAndTeams(level, dancer, levelData) && level !== "SPECIALS" && < LevelButton level={level} data={data} dancer={dancer} index={index} handleClick={handleClick} selectedLevels={selectedLevels} />
                        )}
                      </div>
                      {/* Check if selected dancer level is in selectedLevels */}
                      {selectedLevels[index] && (
                        <div>
                          <p className="mt-3 mb-2" style={{ fontSize: "12px", fontWeight: "500", color: "#68AD3F", }}>{selectedLevels[index]}</p>
                          <div style={{ border: "2px solid rgb(234, 234, 234)", width: "fit-content", }}>
                            {/* check if slected */}
                            {/* {levelData && levelData[selectedLevels[index]]?.filter((item) => item.ages.some(age => dancer.ages.value.includes(age)))?.map((item) => { */}
                            {levelData && filterCompBasedOnAgesAndTeams(selectedLevels[index], dancer, levelData)?.map((item) => {
                              // const group = item?.group?.toLowerCase() === "specials" ? item?.levels[0] : item?.group;
                              const group = item.group;
                              if (group === selectedLevels[index]) {
                                return <CompetitionCheckBox item={item} index={index} dancer={dancer} group={group} checkBoxSelectHandler={checkBoxSelectHandler} selectedLevelData={selectedLevelData} />
                              }
                            })}
                          </div>
                        </div>
                      )}
                      {(levelData && Object.keys(levelData)?.includes("SPECIALS") && levelHasSpecials) && (
                        <div>
                          <p className="mt-3 mb-2" style={{ fontSize: "12px", fontWeight: "500", color: "#68AD3F", }}>
                            SPECIALS
                          </p>
                          <div style={{ border: "2px solid rgb(234, 234, 234)", width: "fit-content", }}>
                            {specialsList?.map((item) => {
                              return <CompetitionCheckBox item={item} index={index} dancer={dancer} group={"SPECIALS"} checkBoxSelectHandler={checkBoxSelectHandler} selectedLevelData={selectedLevelData} />
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>

        <div className="col-md-5 d-flex flex-column right-calc-div" >
          <RegisterInFiesanaaEvent
            edit
            regId={regId}
            prevTotal={prevTotal}
            prevFamilyFee={prevFamilyFee}
            previousCompetition={previousCompetition}
            selectedLevelData={selectedLevelData}
            allDancer={allDancer}
            eventDetails={eventDetails}
            prevDancerTotal={prevDancerTotal}
            deleteItemHandler={deleteItemHandler}
            setshowPaymentPage={setshowPaymentPageHandler}
            clientSecret={clientSecret}
            errormsg={errormsg}
            showPaymentPage={showPaymentPage}
          />
        </div>
      </div>}
    </div>
  );
};

export default FeiseannazEditEvent;
